<template>
  <div :class="$style.assortments">
    <div :class="$style.wrapper">
      <el-button
        size="small"
        type="primary"
        data-test-id="createAssortmentButton"
        @click="submitForm('form')"
      >
        Создать
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="120"
    >
      <el-form-item label="Название" prop="name">
        <el-input
          @input="handleNameInput"
          v-model="form.name"
          data-test-id="nameInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="Имя в URL" prop="slug">
        <el-input v-model="form.slug" data-test-id="slugInput"></el-input>
      </el-form-item>
      <el-form-item label="Активный" label-width="15rem">
        <el-checkbox v-model="form.isActive"></el-checkbox>
      </el-form-item>
      <el-form-item label="Отображать в каталоге" label-width="15rem">
        <el-checkbox v-model="form.isInMainCatalog"></el-checkbox>
      </el-form-item>
      <el-form-item label="Рекламный" label-width="15rem">
        <el-checkbox v-model="form.isPromotional"></el-checkbox>
      </el-form-item>
      <el-form-item label="Использовать разделитель" label-width="15rem">
        <el-checkbox v-model="form.withSeparator"></el-checkbox>
      </el-form-item>
      <el-form-item label="Категория" prop="categoryPath">
        <el-cascader
          v-model="form.categoryPath"
          :options="categoriesTreeData"
          :class="$style.categoryCascader"
          :props="{
            expandTrigger: 'hover',
            label: 'name',
            value: 'id',
            checkStrictly: true,
          }"
          clearable
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="Приоритет" prop="orderField">
        <el-input-number v-model="form.orderField" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="SEO-тексты">
        <SeoTextsBlock :seoTexts="form.seoTextsIds" @getSeo="getSeoTexts" />
      </el-form-item>

      <el-form-item label="SEO-FAQ">
        <SeoFaqsBlock :seoFAQs="form.seoFaqsIds" @getSeo="getSeoFaqs" />
      </el-form-item>

      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
      />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications'

import SeoBlock from '@/components/moleculs/SEO.vue'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'

import { transliterate } from '@/helpers/slug'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

export default {
  components: { SeoBlock, SeoTextsBlock, SeoFaqsBlock },
  ADDWINE_ADMIN_ROUTES,
  mixins: [notifications],
  data() {
    return {
      categoriesTreeData: [],
      form: {
        name: '',
        slug: '',
        orderField: 0,
        isActive: false,
        isInMainCatalog: false,
        withSeparator: false,
        isPromotional: false,
        categoryPath: [],
        categoryId: 0,
        seoTextsIds: [],
        seoFaqsIds: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, загрузите файл',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  created() {
    this.getCategoriesTree()
  },
  methods: {
    getSeoTexts(seoTexts) {
      this.form.seoTextsIds = seoTexts
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqsIds = seoFaqs
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
      this.form.seo.title = value
      this.form.seo.description = value
      this.form.seo.ogTitle = value
      this.form.seo.ogDescription = value
    },

    async getCategoriesTree() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.CategoriesActions.getTree()
      if (error) return
      this.categoriesTreeData = [value]

      loading.close()
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          this.form.categoryId = this.form.categoryPath
            ? this.form.categoryPath[this.form.categoryPath.length - 1]
            : null

          const { error } =
            await delivery.ProductsCore.AssortmentsActions.create(this.form)

          loading.close()

          if (error) return

          this.showNotification('Ассортимент успешно создан', 'success')

          this.$router.push(ADDWINE_ADMIN_ROUTES.CATALOG.ASSORTMENTS.LIST)
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.assortments {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .categoryCascader {
    width: 100%;
  }
}
</style>
